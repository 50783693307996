.iai-layout-heading-container {
    .heading-icon-container {
        border-radius: 8px;
        margin-right: 15px;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        width: 60px;
        height: 60px;
    }

    .layout-heading {
        line-height: 1;
        text-transform: uppercase;
    }
}