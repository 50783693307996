.iai-webchat-container {
    border: 1px solid rgba(182, 178, 175, 0.25);
    box-shadow: 0 5px 15px 0 rgba(182, 178, 175, 0.25);

    position: relative;
    // height: 100vh;

    .webchat-container {
        position: relative;
        /**/
        height: calc(100vh - 275.5px);

        .iai-shimmer-container {
            padding: 25px;
        }
    }

    .webchat-form-container {
        /**/
        padding: 25px;
    }
}
