.application-layout-container {
    display: flex;
    flex-direction: row;
    min-height: 100vh;

    .application-layout-sider {
        border-right: 1px solid rgba(182, 178, 175, 0.25);
        box-shadow: 0 5px 15px 0 rgba(182, 178, 175, 0.25);
        overflow: auto;
        /**/
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;
    }

    .application-layout-child-container {
        flex: auto;
        padding: 30px;
        margin-left: 100px;
        /**/
        overflow: hidden;
    }
}