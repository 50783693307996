.callout-secondary-heading {
    font-size: 0.875rem;
    font-style: italic;
    font-weight: 400;
    line-height: 1.5;
    display: block;
    margin-bottom: 10;
}

.callout-text {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    display: block;
}
.ms-ComboBox-callout {
    min-width: 65px;
}