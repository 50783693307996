.setting-card-container {   
    border: 1px solid rgba(135, 135, 135, 0.35);
    box-shadow: 0 5px 15px 0 rgba(182, 178, 175, 0.25);
    color: rgb(50, 50, 50);
    cursor: pointer;
    font-size: 1.5rem;
    font-weight: 400;
    outline: transparent none medium;
    text-align: center;
    text-decoration: none;
    transition: all 0.3s cubic-bezier(0.75, 0, 0.175, 1);
    user-select: none;
    /**/
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 285px;
    word-wrap: break-word;
    padding: 10;
    position: relative;
    justify-content: center;

    &::after {
        position: absolute;
        bottom: -1px;
        right: 0;
        left: 0;
        background-color: rgb(50, 50, 50);
        height: 3.5px;
        width: 0;
        transition: all 0.3s cubic-bezier(0.75, 0, 0.175, 1);
        content: "";
    }

    &:hover {
        border-color: rgba(135, 135, 135, 0.15);
        color: rgb(255, 255, 255);
    }

    &:hover::after {
        width: 100%;
    }

    &.isActive {
        border-color: rgba(135, 135, 135, 0.15);
        color: rgb(255, 255, 255);
    }

    &.isActive::after {
        width: calc(100% + 2px);
    }

    .setting-card-body {
        flex: 0 0 auto;
        padding: 1rem;
        text-align: center;

        .setting-card-icon {
            font-size: 2.5rem;
        }

        .setting-card-description {
            display: block;
            font-size: 1rem;
            font-weight: 500;
            line-height: 1.5rem;
        }
    }
}
