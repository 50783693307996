.ticket-view-page-container {
    .ticket-details-data {
        display: block;
        font-size: 1rem;
        font-style: italic;
        font-weight: 500;
        line-height: 1.5;

        &.ticket-details-heading {
            font-size: 1.75rem;
            font-style: normal;
            font-weight: 500;
            line-height: 1.5;
        }
    }
}
