::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

::-webkit-scrollbar-thumb {
    background: rgb(75, 75, 75);
    border-radius: 0px;
}

::-webkit-scrollbar-thumb:hover {
    background: rgb(30, 30, 30);
}

::-webkit-scrollbar-track {
    background: rgb(255, 255, 255);
    border-radius: 0px;
    box-shadow: inset 5px 10px 15px rgb(182, 178, 175);
}
