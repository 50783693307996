.iai-widget-shimmer-container {
    border: 1px solid rgba(182, 178, 175, 0.25);
    box-shadow: 0 5px 15px 0 rgba(182, 178, 175, 0.25);
    display: flex;
    flex-direction: column;
    width: 100%;
    word-wrap: break-word;

    .iai-widget-shimmer-head {
        border-bottom: 1px solid rgba(182, 178, 175, 0.25);
        box-shadow: 0 5px 15px 0 rgba(182, 178, 175, 0.25);
        padding: 10px 0 10px 0;
        position: relative;
    }

    .iai-widget-shimmer-body {
        flex: 1 1 auto;
        padding: 1rem;
        text-align: center;

        .iai-widget-shimmer-description {
            display: block;
        }
    }
}
