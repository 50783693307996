.iai-callout-container-file {
    align-items: stretch;
    display: flex;
    flex-flow: column nowrap;
    width: 30%;

    .callout-container-header-file {
        align-items: flex-start;
        display: flex;
        flex: 1 1 auto;
        padding: 10px 10px 0 10px;

        .callout-heading-file {
            display: block;
            font-size: 1.25rem;
            font-weight: 600;
            line-height: 1.5;
            padding: 10px;
        }
    }

    .callout-container-body-file {
        font-size: 0.85rem;
        font-weight: 400;
        padding: 0 10px 10px 10px;

        .callout-container-content-file {
            align-items: center;
            display: flex;
            font-size: 0.85rem;
            font-weight: 400;
            padding: 10px;

            .callout-container-content-file-column-icon {
                flex: 10;
                padding-left: 15px;
            }
            .callout-container-content-file-column-title {
                flex: 60;
            }
            .callout-container-content-file-column-date {
                flex: 30;
            }
        }
    }

    .callout-close-btn-file {
        margin-left: auto;
    }
}
