.report-card-container {
    box-shadow: 0 5px 15px 0 rgba(182, 178, 175, 0.25);
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 285px;
    word-wrap: break-word;

    .report-card-body {
        flex: 1 1 auto;
        padding: 1rem;
        text-align: center;

        .report-card-description {
            display: block;
            font-size: 3.5rem;
            font-weight: 500;
            line-height: 1.5rem;
        }
    }
}
