.iai-widget-container {
    border-radius: 10px;
    // box-shadow: 0 5px 15px 0 rgba(182, 178, 175, 0.25);
    padding: 1rem;
    padding-top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 200px;
    transition: all 0.3s cubic-bezier(0.75, 0, 0.175, 1);
    text-decoration: none;
    color: inherit;
    cursor: pointer;

    .widget-description {
        transition: all 0.3s cubic-bezier(0.75, 0, 0.175, 1);
    }

    &:hover {
        text-decoration: none;
        color: inherit;
    }
}