.layout-container {
    .layout-bg-container,
    .layout-child-container {
        /**/
        opacity: 0;
        animation-name: fade-in;
        animation-timing-function: cubic-bezier(0.75, 0, 0.175, 1);
        animation-duration: 0.75s;
        animation-fill-mode: forwards;
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
