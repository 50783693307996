.Toastify__toast {
    .Toastify__toast-icon {
        align-self: flex-start;
        font-size: 20px;
        margin-inline-end: 10px;
        width: 25px;
    }

    .iai-notification {
        .iai-notification-title {
            color: #000000d9;
            font-size: 16px;
            line-height: 1.5;
            margin-bottom: 5px;
        }

        .iai-notification-message {
            font-size: 14px;
            line-height: 1.5;
        }
    }
}

.Toastify__toast--default {
    i {
        color: #e83782;
    }
}

.Toastify__toast--info {
    i {
        color: #1890ff;
    }
}

.Toastify__toast--success {
    i {
        color: #52c41a;
    }
}

.Toastify__toast--warning {
    i {
        color: #fadb14;
    }
}

.Toastify__toast--error {
    i {
        color: #ff4d4f;
    }
}
