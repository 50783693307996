.iai-callout-container {
    align-items: stretch;
    display: flex;
    flex-flow: column nowrap;
    // width: 320px;
    max-width: 750px;

    .callout-container-header {
        align-items: flex-start;
        display: flex;
        flex: 1 1 auto;
        padding: 10px 10px 10px 20px;

        .callout-heading {
            display: block;
            font-size: 1.25rem;
            font-weight: 600;
            line-height: 1.5;
            /**/
            margin-right: 10px;
        }
    }

    .callout-container-body {
        align-items: center;
        display: flex;
        flex: 4 4 auto;
        font-size: 0.85rem;
        font-weight: 400;
        padding: 0 20px 20px 20px;
    }

    .callout-close-btn {
        margin-left: auto;
    }
}
