.iai-loading-screen-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 99999;
    width: 100%;
    height: 100%;
    position: fixed;
    right: 0;
    bottom: 0;

    .loading-screen-logo {
        transform: rotateY(0deg);
        animation-name: loading-logo;
        animation-delay: 1s;
        animation-duration: 2s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-timing-function: cubic-bezier(0.75, 0, 0.175, 1);
    }
}

@keyframes loading-logo {
    0% {
        transform: rotateY(0deg);
    }

    100% {
        transform: rotateY(360deg);
    }
}